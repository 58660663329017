import React from "react"
import { Container, Grid } from '@material-ui/core'
import {
  SocialIconsListFixed,
} from '../styles/pages/home'

import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  YouTube as YoutubeIcon,
} from '@material-ui/icons'

import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageTitle } from '../components/PageTitle'

export default function NossaHistoriaPage(props) {
  return (
    <Layout>
      <SEO title="Nossa história" description="Página que conta um pouco da história da Faculdade Jardins." />
      <Container style={{ marginTop: 50 }}>
        <PageTitle> Nossa <span style={{ color: '#ef4e45' }}>história</span></PageTitle>
        <Grid container style={{ marginTop: 70 }}>
          <Grid sm={12}>
            <p>    A Faculdade Jardins (IES nº 15133) foi Credenciada pela Portaria MEC
              nº 741, de 09 de Agosto de 2013, publicada no D.O.U. 12/08/2013,
              para atuar na educação superior, modalidade presencial; Credenciada
              pela Portaria MEC nº 173, de 03 de Fevereiro de 2017, publicada no
              D.O.U de 06/02/2017, para atuar na Pós-Graduação Lato Sensu,
              modalidade à distância; e Credenciada pela Portaria MEC nº 918, de
              15 de Agosto de 2017, publicada no D.O.U. de 16/08/2017, para atuar
              na Graduação, modalidade à distância. Atualmente oferece: Graduações
              presenciais na Sede da Faculdade; Pós-Graduações presenciais na Sede
              da Faculdade e em Polos em diversas cidades da Bahia, de Sergipe e
              do Brasil; Graduações e Pós-Graduações a distância para todo
              território nacional, vinculadas a Polos nos Estados de Sergipe,
              Bahia, São Paulo e em expansão para todo o Brasil; além de cursos de
              extensão, qualificação e aperfeiçoamento presenciais e a distância
              para nossos colaboradores, professores, alunos, comunidades do
              entorno e ao público em geral.</p>

            <SocialIconsListFixed style={{ margin: '40px 0 '}}>
              <a
                href="http://facebook.com/facjardins"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon fontSize="large" />
                <span>Facebook</span>
              </a>
              <a
                href="https://www.instagram.com/facjardins.oficial/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon fontSize="large" />
                <span>Instagram</span>
              </a>
              <a
                href="https://www.youtube.com/channel/UCsGxry1VPtzeSLPnVdTz6NQ"
                target="_blank"
                rel="noopener noreferrer"
              >
                <YoutubeIcon fontSize="large" />
                <span>Youtube</span>
              </a>
            </SocialIconsListFixed>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}
