import styled from 'styled-components'
import { Container, Box, Paper } from '@material-ui/core'

export const SlideContainer = styled(Container)`
  width: 1077px;
  margin-top: 20px;
  & {
    .carousel-initialized {
      border-radius: 4px;
      height: 400px;
    }
    @media (min-width: 1280px) {
      max-width: 100%;
    }
  }
`

export const Slide = styled(Box)`
  & {
    background-image: url("${props => props.img}");
    height: 400px;
    background-size: contain;
    background-repeat: no-repeat
  }
`

export const SlideTitle = styled.h2`
  color: ${props => props.color};
  font-size: ${props => (props.font ? props.font + 'px' : '48px')};
  margin-left: 20px;
  margin-top: 100px;
`

export const SlideSubtitle = styled.span`
  display: flex;
  justify-content: center;
  font-size: ${props => (props.font ? props.font + 'px' : '20px')};
  margin-top: 20px;
  color: ${props => (props.color ? props.color : '#d85935')};
  font-weight: 600;
`

export const ActionButton = styled.button`
  padding: 15px;
  box-shadow: none;
  color: #fff;
  background: #169438;
  border: 2px solid #169438;
  border-radius: 7px;
  font-size: 17px;
  cursor: pointer;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: ease 0.3s;
  &:hover {
    background-color: #882227;
    border-color: #882227;
    color: #10dc47;
  }
`

export const SectionTitle = styled.h2`
  font-size: 2em;
  text-transform: uppercase;
  font-weight: 500;
  border-bottom: 2px solid #ef4e45;
  padding: 12px;
`

export const VideoWrapper = styled(Container)`
  margin: 0;
  display: inline-block;
  position: relative;
  @media (max-width: 1108px) {
    top: 0;
    iframe {
      margin-left: -30px !important;
    }
  }
  @media (max-width: 400px) {
    width: 400px;
    iframe {
      width: 100%;
      margin-top: 20px;
    }
  }
`


export const SocialIconsListFixed = styled(Box)`
  display: flex;
  position: relative;
  a {
    display: flex;
    align-items: center;
    color: #ef4e45;
    font-weight: 600;
    margin: 0 10px;
  }
`


export const SocialIconsList = styled(Box)`
  display: flex;
  position: relative;
  bottom: 70px;
  left: 26px;
  a {
    display: flex;
    align-items: center;
    color: #ef4e45;
    font-weight: 600;
    margin: 0 10px;
  }
  @media (max-width: 400px) {
    left: -35px;
  }
`

export const Card = styled(Paper)`
  display: inline-block;
  width: 370px;
  height: 250px;
  padding: 15px;
  margin: 30px 20px;
  .MuiAvatar-root {
    width: 70px;
    height: 70px;
    left: 50%;
    transform: translate(-50%, 0);
    margin-bottom: 20px;
  }
  svg {
    float: right;
  }
  p {
    margin-bottom: 10px;
    text-align: justify;
  }
  span {
    display: flex;
    justify-content: center;
    font-size: 22px;
    font-weight: bold;
    color: #222;
  }
`

export const CourseWrapper = styled(Box)`
  display: flex;
  & > p {
    padding: 28px 10px;
    height: fit-content;
  }
  @media (max-width: 400px) {
    ul {
      display: none;
    }
    p {
      width: 100%;
    }
  }
`
